export const VESSEL_FIELD_DISPLAY_CONFIG_KEY = 'vessel_field_display_config'

export const vesselFieldsFixed = [
    { key: 'IMO',  label: 'IMO' },
    { key: 'name', label: 'Vessel Name' },
    { key: 'length', label: 'LOA' },
    { key: 'beam', label: 'Beam' },
    { key: 'bridgeToBow', label: 'B. To Bridge' },
    { key: 'bridgeToStern', label: 'S. To Bridge' },
    { key: 'vesselType', label: 'Vessel Type' },
    { key: 'emails', label: 'Vessel Email(s)' },
]

export const vesselFieldsEditable = [
    { key: 'height', label: 'HOA' }, // either height or air draft must be enabled
    { key: 'bittsFwd', label: 'Bitts (SWL) Fwd.' },
    { key: 'bittsAft', label: 'Bitts (SWL) Aft' },
    { key: 'trackControl', label: 'Track Control' },
    { key: 'safeWorkLoad', label: 'Safe Work Load' }
    /* not shown in admin UI at all - see https://masterpilotexchange.atlassian.net/browse/EMPX-491?focusedCommentId=10742
    'maxDraft',
    'source',
    'measurementsUnit',
    'safeWorkLoadUnit'
    */
]


export const vesselMetaDataFieldsFixed = [
    { key: 'gross_tonnage', label: 'Gross Tonnage' },
    { key: 'comments', label: 'Last Pilot(s) Remarks' }
]

export const vesselMetaDataFieldsEditable = [
    { key: 'air_draft',  label: 'Air Draft' },// either air draft or height must be enabled
    { key: 'year_built', label: 'Year Built' },
    { key: 'DWT_summer', label: 'DWT (Summer)' },
    { key: 'draft_summer', label: 'Draft (Summer)' },
    { key: 'call_sign', label: 'Callsign' },
    { key: 'mmsi', label: 'MMSI' },
    { key: 'prop_no', label: 'Propeller No' },
    { key: 'cpp_fixed', label: 'CPP/Fixed' },
    { key: 'prop_azi', label: 'Prop/Azi' },
    { key: 'rotation', label: 'Rotation' },
    { key: 'prop_effect', label: 'Prop Effect' },
    { key: 'rudder', label: 'Rudder' },
    { key: 'thr_fwd', label: 'Thr. Fwd' },
    { key: 'thr_aft', label: 'Thr. Aft' },
    { key: 'main_engine', label: 'Main Engine' },
    { key: 'sternToAftContBay', label: 'S.-Aft Cont.B' },
    { key: 'beamToFwdContBay', label: 'B.-Fwd Cont.B.' },
    { key: 'astern_pwr', label: 'Astern Power' },
    { key: 'full_hbr', label: 'Full Ahead' },
    { key: 'squat_full_hbr', label: 'Squat at full Harbour Speed' },
    { key: 'half_ahead', label: 'Half Ahead' },
    { key: 'slow_ahead', label: 'Slow Ahead' },
    { key: 'dead_slow', label: 'Dead Slow' },
    { key: 'sea_speed', label: 'Sea Speed' },
    { key: 'vessel_depth', label: 'Vessel Depth' },
    { key: 'freeboard', label: 'Freeboard' },
    { key: 'net_tonnage', label: 'Net Tonnage' },
    { key: 'me_power_limitation', label: 'Main Engine Power Limitation' },
    { key: 'me_limited_power', label: 'Main Engine Limited Power' },
    { key: 'me_limiter_immediately_overridable', label: 'Main Engine Limiter Overridable' },
]

export const defaultDisabledVesselFieldsPilotApp = () => ({
    'astern_pwr': true,
    'full_hbr': true,
    'squat_full_hbr': true,
    'half_ahead': true,
    'slow_ahead': true,
    'dead_slow': true,
    'sea_speed': true,
    'vessel_depth': true,
    'freeboard': true,
    'net_tonnage': true,
    'prop_effect': true,
})

export const defaultDisabledVesselFieldsMasterView = () => ({
    'comments': true,
    'emails': true,
    'vesselType': true,
    'year_built': true,
    'DWT_summer': true,
    'draft_summer': true,
    'call_sign': true,
    'prop_no': true,
    'prop_azi': true,
    'sternToAftContBay': true,
    'beamToFwdContBay': true,
    'astern_pwr': true,
    'full_hbr': true,
    'squat_full_hbr': true,
    'half_ahead': true,
    'slow_ahead': true,
    'dead_slow': true,
    'sea_speed': true,
    'vessel_depth': true,
    'freeboard': true,
    'net_tonnage': true,
    'prop_effect': true,
})

export interface VesselFieldDisplayConfig {
    disabled_pilot_app: { [field_name: string]: boolean }
    disabled_master_view: { [field_name: string]: boolean }
}

export interface IPreferencesVesselFieldDisplayConfig {
    id?: number
    key: 'vessel_field_display_config'
    createdAt?: string
    updatedAt?: string
    deletedAt?: string
    port?: any
    uuid?: string
    metadata: VesselFieldDisplayConfig
}

export const defaultVesselFieldDisplayConfig: IPreferencesVesselFieldDisplayConfig = {
    uuid: '', // no uuid means it doesn't exist in db yet
    key: VESSEL_FIELD_DISPLAY_CONFIG_KEY,
    metadata: {
        disabled_pilot_app: defaultDisabledVesselFieldsPilotApp(),
        disabled_master_view: defaultDisabledVesselFieldsMasterView()
    }
}
