import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  Typography,
  RadioGroup,
  Radio,
  FormControlLabel,
} from '@material-ui/core'
import { PortShape } from 'src/utils/types'
import { WEATHER_SOURCE } from 'src/constants/settings'
import WeatherForm from 'src/components/organisms/WeatherForm'
import Fieldset from 'src/components/organisms/Fieldset'
import weatherStore from 'src/store/weatherConfig'


const ShadedBox = styled(Box)`
  && {
    background-color: ${({ theme }) => theme.palette.background.grey};
    border-radius: ${({ theme }) => theme.spacing(1)}px;
  }
`
const StyledFormControlLabel = styled(FormControlLabel)`
  && {
    .MuiFormControlLabel-label {
      font-size: ${({ theme }) => theme.typography.pxToRem(21)};
    }
  }
`
const SectionHeader = styled(Typography).attrs({ variant: 'h3' })`
  && {
    margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  }
`

export const Weather = ({ port, disabled }) => {

  const [submitting, setSubmitting] = useState(false)
  const dispatch = useDispatch()
  const [radioApiType, setRadioApiType] = useState(WEATHER_SOURCE.worldWeatherOnline.apiType)

  const weatherConfig = useSelector(weatherStore.selectors.getPortConfig)
  const activeConfig = useSelector(weatherStore.selectors.getActiveConfig)

  useEffect(() => {
    if (port) {
      dispatch(weatherStore.actions.getList({ portUuid: port.uuid }))
    }
  }, [dispatch, port])

  useEffect(() => {
    if (activeConfig && activeConfig.apiType) {
      setRadioApiType(activeConfig.apiType)
    }
  }, [activeConfig && activeConfig.apiType])

  const getSourceByApiType = (apiType) =>
    Object.values(WEATHER_SOURCE).find(s => apiType === s.apiType)

  const source = getSourceByApiType(radioApiType)

  const getExistingConfigByApiType = (apiType) =>
    (weatherConfig || []).find(config => apiType === config.apiType)

  const requiresInput = (apiType) =>
    apiType === WEATHER_SOURCE.stormGeo.apiType ||
    apiType === WEATHER_SOURCE.stormGeoV2.apiType ||
    // apiType === WEATHER_SOURCE.metOcean.apiType ||
    apiType === WEATHER_SOURCE.metOceanV2.apiType ||
    apiType === WEATHER_SOURCE.portQuest.apiType

  const handleRadioChange = async (e) => {

    const apiType = e.target.value
    setRadioApiType(apiType)

    const existingConfig = getExistingConfigByApiType(apiType)
    if (existingConfig) {
      await updateWeatherConfig({ ...existingConfig, isSelected: true })

    } else if (!requiresInput(apiType)) {
      const source = getSourceByApiType(apiType)
      await createWeatherConfig({
        apiType,
        apiUrl: source.url
      })
    }
  }

  const createWeatherConfig = async (config) => {
    setSubmitting(true)
    await dispatch(
      weatherStore.actions.create({
        ...config,
        port,
      })
    )
    setSubmitting(false)
  }

  const updateWeatherConfig = async (config) => {
    setSubmitting(true)
    await dispatch(
      weatherStore.actions.update({
        ...config,
        port
      })
    )
    setSubmitting(false)
  }

  const handleSave = async (config) => {
    activeConfig && config.apiType === activeConfig.apiType
      ? await updateWeatherConfig(config)
      : await createWeatherConfig({ apiType: radioApiType, ...config })
  }

  const removeWhitespace = (apiType) =>
    apiType !== WEATHER_SOURCE.worldWeatherOnline.apiType
      ? apiType.replace(/\s/g, '')
      : apiType

  return (
    <>
      <Fieldset
        disabled={disabled}
        title="Weather data source"
        subtitle="Please select the weather data source you would like to use for
        eMPX. The pilots will see predicted weather from that source for the weather locations defined above."
        description="Manage the source of your weather data"
      >
            <Box mb={1}>
              <RadioGroup
                value={radioApiType}
                onChange={handleRadioChange}
                name="weatherSource"
              >
                {Object.values(WEATHER_SOURCE).map((value) => (
                  <StyledFormControlLabel
                    key={value.id}
                    value={value.apiType}
                    control={<Radio color="primary" />}
                    label={removeWhitespace(value.apiType)}
                  />
                ))}
              </RadioGroup>
            </Box>

            {
              source &&
              <ShadedBox p={3}>
                <SectionHeader>
                  {removeWhitespace(radioApiType)}
                </SectionHeader>
                <Typography>{source.description}</Typography>
                {
                  requiresInput(radioApiType) && (
                    <WeatherForm
                      disabled={submitting}
                      port={port}
                      onSubmit={handleSave}
                      weatherConfig={
                        { ...getExistingConfigByApiType(radioApiType) }
                      }
                    />
                  )
                }
              </ShadedBox>
            }
      </Fieldset>
    </>
  )
}

Weather.propTypes = {
  port: PropTypes.shape(PortShape),
  disabled: PropTypes.bool,
  weatherConfig: PropTypes.array,
}
export default Weather
